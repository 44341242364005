@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
:root {
  --color: #6d6d6d;
  --themeColor: #000;
  --inputBg: #363943;
  --colorAlt: #000;
  --bgColor: #fff;
}
body {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  color: #6d6d6d;
  color: var(--color);
  text-decoration: none;
  background-color: #fff;
  background-color: var(--bgColor);
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
img {
  width: 100%;
}
*,
:after,
:before {
  box-sizing: border-box;
}
button {
  cursor: pointer;
}
.slick-prev,
.slick-next {
  width: 70px !important;
  height: 70px !important;
  background: rgba(196, 196, 196, 0.76) !important;
  border-radius: 50% !important;
  padding: 10px !important;
}

.slick-prev {
  left: -18px !important;
  z-index: 9 !important;
}
.slick-next {
  right: -18px;
}
.d-iblock {
  display: inline-block;
}
@media (max-width: 991px) {
  .slick-prev,
  .slick-next {
    width: 50px !important;
    height: 50px !important;
  }
  .slick-prev {
    left: -8px !important;
  }
  .slick-next {
    right: -8px !important;
  }
}
.banner-align {
  align-items: end;
}
.MuiIconButton-edgeStart {
  margin: 0 !important;
  padding: 0 !important;
}
.MuiInputBase-colorPrimary {
  background-color: #fff;
}
.MuiTextField-root {
  width: 100%;
}
.MuiChip-colorDefault {
  background-color: #000 !important;
  color: #fff !important;
  font-size: 16px !important;
  padding-right: 20px !important;
}

@media (max-width: 991px) {
  .fld {
    flex-direction: column-reverse !important;
  }
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 1%, 0);
    transform: translate3d(0, 1%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 1%, 0);
    transform: translate3d(0, 1%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

ul.media-btn {
  display: inline-block;
  list-style: none;
  margin: 20px auto 0;
  text-align: center;
  padding: 0;
}
ul.media-btn li {
  display: inline-block;
  margin: 0 10px;
}
.btn-photo,
.btn-start,
.btn-pause,
.btn-resume,
.btn-stop,
.btn-save {
  background: transparent;
  margin: 0;
  padding: 10px;
  border-radius: 50%;
  border: 4px solid #6d6d6d;
  border: 4px solid var(--color);
  vertical-align: bottom;
  height: 70px;
  width: 70px;
}
.btn-start svg,
.btn-pause svg,
.btn-resume svg,
.btn-stop svg,
.btn-save svg {
  fill: #6d6d6d;
  fill: var(--color);
}
button.btn-photo {
  padding: 13px;
}
.mt10p {
  margin-top: 20%;
}
.content-middle {
  display: flex;
  justify-content: center;
  height: calc(100vh - 300px);
  align-items: center;
}
.full-width {
  width: 100%;
  height: 100%;
}
.video-js {
  width: 100%;
  height: 100%;
}
.spinner-border {
  border-width: 5px !important;
}
.csLoader .spinner-border {
  width: 65px;
  height: 65px;
}
.text-primary {
  color: #000 !important;
  color: var(--themeColor) !important;
}
.overlay {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.74);
}
.overlay {
  opacity: 0;
  transition: 0.3s all ease-in-out;
}
.vidco-play-icon {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  right: 0;
  z-index: 3;
}
.vidco-play-icon a {
  display: block;
  width: 100%;
}
.vidco-play-icon svg {
  height: 75px;
  width: 75px;
}
.cv-display {
  height: 100%;
  width: 100%;
  padding-top: 75% !important;
}
.video-js .vjs-big-play-button {
  border: 0 !important;
  background-color: rgba(210, 100, 103, 0.7) !important;
}
.nav-link {
  font-weight: 700;
  color: #8a8383;
}

.video-js.vjs-fluid {
  overflow: hidden;
}

.dropdownmenu:hover > ul {
  display: block;
}
.ma--10 {
  margin-left: -10px;
  margin-right: -10px;
}
.MuiFormControlLabel-root {
  margin-bottom: -10px !important;
}
.video-js .vjs-control-bar {
  height: 4em !important;
  background-color: rgba(196, 196, 196, 0.5) !important;
}
.video-js .vjs-time-control {
  font-size: 1.3em !important;
}
.vjs-button > .vjs-icon-placeholder:before {
  font-size: 2.5em !important;
}
.video-wrapper {
  border-radius: 5px;
  overflow: hidden;
}
.modal-content {
  background-color: var(--colorBG);
}
.modal-title {
  color: #6d6d6d;
  color: var(--color);
  font-size: 20px;
}
.modal-header .close {
  color: #6d6d6d;
  color: var(--color);
  font-weight: 300;
}
.modal-header .close span {
  font-size: 30px;
}
.form-label {
  color: #6d6d6d;
  color: var(--color);
  font-weight: 500;
}
.text-center {
  text-align: center;
}
.Mui-focused {
  color: #000 !important;
}
.MuiOutlinedInput-notchedOutline {
  border-color: #000 !important;
}
.MuiButton-containedPrimary {
  background-color: #000 !important;
}
.MuiButton-containedPrimary.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
}
.font-24 {
  font-size: 24px !important;
}
.theme-color {
  color: #000 !important;
  color: var(--themeColor) !important;
}
.mb-5 {
  margin-bottom: 30px;
  margin-top: 20px;
}
.model-cus-title {
  background-color: #000;
  background-color: var(--themeColor);
  color: #fff;
  color: var(--bgColor);
  padding: 10px 15px !important;
}
.custom-radio {
  border: 1px solid #a9b3c6;
  border-radius: 3px;
  padding: 1px 15px 10px;
  margin-bottom: 10px;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
}
.css-o4b71y-MuiAccordionSummary-content {
  justify-content: flex-end !important;
}
.pac-container.pac-logo {
  z-index: 9999;
}
.pac-target-input {
  font: inherit;
  letter-spacing: inherit;
  color: #6d6d6d;
  color: var(--color);
  border: 0;
  background: none;
  margin: 0;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
          animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
          animation-duration: 10ms;
  padding: 15px 14px;
  border-radius: 4px;
  border: 1px solid #000;
}
.loader-root {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: fixed;
  width: 100%;
  background-color: #fff;
  z-index: 999;
  top: 0;
}

